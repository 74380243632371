import { isArray, isObject, isEmpty } from "lodash-es";
function isValidContentArray(object) {
  if (!isArray(object)) {
    return false;
  }
  for (const o of object) {
    if (!isValidContent(o)) {
      return false;
    }
  }
  return true;
}
function isValidContent(object) {
  return isObject(object) && !isEmpty(object.name) && !isEmpty(object.uuid);
}
export {
  isValidContent,
  isValidContentArray
};
